import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import {
  toREM,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import {
  MEDIA_DESKTOP,
  MEDIA_MOBILE,
} from "../../helpers/theme";
import MinusIcon from "../../assets/images/minus_icon.svg";
import PlusIcon from "../../assets/images/plus_icon.svg";

export const AccordionDetails = ({
  accordionHeadline,
  accordionSubheadline,
  accordionItems,
}: {
  accordionHeadline?: string;
  accordionSubheadline?: string;
  accordionItems?: {
    title: string;
    description: string;
  }[];
}): ReactElement => {
  const [activeIndex, setActiveIndex] =
    useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  if (
    !accordionHeadline ||
    !accordionItems ||
    !accordionItems.length
  )
    return <></>;
  return (
    <Container>
      <Headline>{accordionHeadline}</Headline>
      {accordionSubheadline && (
        <Subheadline
          dangerouslySetInnerHTML={{
            __html: accordionSubheadline,
          }}
        />
      )}
      {accordionItems?.map((item, index) => (
        <AccordionItemContainer key={index}>
          <AccordionTitle
            onClick={() => toggleAccordion(index)}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
            />
            <Icon
              src={
                index === activeIndex ? MinusIcon : PlusIcon
              }
              aria-hidden="true"
            />
          </AccordionTitle>
          {activeIndex === index && (
            <AccordionContent
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          )}
        </AccordionItemContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  background: #ccb983;
  padding: 1.5rem;
  width: 100%;
  margin-bottom: ${vwMobile(60)};
  color: #033305;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  ${MEDIA_MOBILE} {
    &:first-child {
      margin-top: ${vwMobile(40)};
    }
  }
  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(60)};
    margin-top: 0px;
    padding: 2.4rem 2rem;
  }
`;

const Icon = styled.div<{ src: string }>`
  mask-image: url(${({ src }) => src});
  mask-position: center;
  mask-size: contain;
  background: #033305;
  width: ${toREM(14)}rem;
  height: ${toREM(14)}rem;
  ${MEDIA_DESKTOP} {
    width: ${toREM(18)}rem;
    height: ${toREM(18)}rem;
  }
`;

const Headline = styled.p`
  font-size: ${toREM(32)}rem;
  font-weight: 900;
  text-transform: uppercase;
  ${MEDIA_DESKTOP} {
    font-size: ${toREM(48)}rem;
  }
`;

const Subheadline = styled.p`
  font-size: ${toREM(18)}rem;
  font-weight: 500;
  margin-top: ${toREM(8)}rem;
  ${MEDIA_DESKTOP} {
    font-size: ${toREM(20)}rem;
  }
  a {
    color: inherit;
  }
`;

const AccordionItemContainer = styled.div`
  margin-top: ${toREM(16)}rem;
  border-top: 1px solid #000;
  &:last-child {
    border-bottom: 1px solid #000;
  }
`;

const AccordionTitle = styled.div`
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  padding-left: 0px;
  ${MEDIA_DESKTOP} {
    padding-top: 0.8rem;
  }
  p {
    margin: 0px;
    font-weight: 800;
    font-size: ${toREM(18)}rem;
  }
`;

const AccordionContent = styled.div`
  padding-bottom: 0.6rem;
  p {
    font-size: ${toREM(18)}rem;
  }
  a {
    color: inherit;
  }
`;
