import React, { useCallback } from "react";
import { useIntl } from "../hooks/useIntl";
import { graphql } from "gatsby";
import get from "lodash/get";

import Page from "../components/Page";
import ProductDetailInfo, {
  ProductDetailInfoProps,
} from "../components/ProductDetailInfo";
import { getTranslations } from "../helpers/translations";
import { getAssetURL } from "../helpers/assets";
import FeaturedRecipe, {
  FeaturedRecipeProps,
} from "../components/FeaturedRecipe";
import WantMore, { WantMoreProps } from "../components/WantMore/WantMore";
import QuestionsAndAnswers, {
  QuestionsAndAnswersProps,
} from "../components/QuestionsAndAnswers";
import HowItsMade, { HowItsMadeProps } from "../components/HowItsMade";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { cleanHTMLString } from "../helpers/html";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { isPublished } from "../helpers/directus";
import { ROUTES } from "../helpers/routes";

const Product = ({
  data,
  pageContext
}: {
  data: any,
  pageContext: {
    slug: string;
    variantId?: string;
  }
}) => {
  const variantId = pageContext?.variantId;
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.product_page_by_id.translations",
      intl.locale
    );
  }, [data]);

  const getProductDetailInfoData = useCallback((): ProductDetailInfoProps => {
    const translatedData = getRootTranslatedData();
    const translatedProduct = getTranslations(
      translatedData,
      "product.translations",
      intl.locale
    );

    const { variants = [] } = translatedProduct || {};
    const publishedVariants = isPublished(translatedData?.variants_status)
      ? variants
      : [];

    return {
      productSlug: pageContext?.slug,
      product_list: publishedVariants
        ?.map(({ product_variant_id }: { product_variant_id: any }) => {
          const translatedVariant = getTranslations(
            product_variant_id,
            "translations",
            intl.locale
          );

          const translatedDetail = getTranslations(
            translatedVariant,
            "detail.translations",
            intl.locale
          );

          const publishedDetail = isPublished(translatedDetail?.status)
            ? translatedDetail
            : null;

          return {
            product_variant_id: product_variant_id?.id,
            product_variant_slug: product_variant_id?.variant_slug,
            status: translatedVariant?.status,
            title_1: translatedVariant?.title,
            subtitle: translatedVariant?.subtitle_enabled
              ? translatedVariant?.subtitle
              : null,
            description: translatedVariant?.description,
            image: translatedVariant?.small_image,
            image_1: translatedVariant?.small_image,
            image_1_alt: translatedVariant?.small_image_alt,
            available_quantities_list: translatedVariant?.options
              ?.map(
                ({ available_quantity_id }: { available_quantity_id: any }) => {
                  const translatedOptions =
                    getTranslations(
                      available_quantity_id,
                      "translations",
                      intl.locale
                    ) ?? {};
                  return {
                    status: translatedOptions?.status,
                    left_button_label: labels?.shopNow,
                    optional_button_label: labels?.buyOnline,
                    left_button_link: translatedOptions?.shop_now_link,
                    right_button_label: labels?.findNearMe,
                    right_button_link: translatedOptions?.find_near_me_link,
                    quantity: translatedOptions?.quantity,
                    image_1_alt: translatedOptions?.image_alt,
                    image_1: translatedOptions?.image,
                  };
                }
              )
              ?.filter(({ status }: { status: string }) => isPublished(status)),
            available_quantities_label: labels?.availableQuantities,
            benefitImages: publishedDetail?.badges
              ?.map(({ badges_id }) => {
                const translatedBadge = getTranslations(
                  badges_id,
                  "translations",
                  intl.locale
                );
                return {
                  status: translatedBadge?.status,
                  src: getAssetURL(translatedBadge?.image),
                  alt: translatedBadge?.image_alt,
                };
              })
              ?.filter(({ status }: { status: string }) => isPublished(status)),
            accordionHeadline: publishedDetail?.accordion_headline,
            accordionSubheadline: publishedDetail?.accordion_subheadline,
            accordionItems: publishedDetail?.accordion_items,
            badgesDisclaimer: publishedDetail?.badges_disclaimer,
            ingredientsTitle: labels?.ingredientsTitle,
            ingredientsDetail: cleanHTMLString(
              publishedDetail?.ingredients_detail
            ),
            factsTitle: labels?.nutritionsFactsTitle,
            facts: publishedDetail?.nutritions_facts_details,
            factsTable: {
              header: [
                get(publishedDetail, "nutritions_facts_data[0].title"),
                get(publishedDetail, "nutritions_facts_data[0].amount"),
                get(publishedDetail, "nutritions_facts_data[0].daily_value"),
              ],
              body: (publishedDetail?.nutritions_facts_data || [])
                .filter((e, i) => i > 0)
                .reduce((results, fact) => {
                  if (fact.title) {
                    results.push({
                      label: fact?.title,
                      amount: fact?.amount,
                      percentage: fact?.daily_value,
                    });
                  }
                  return results;
                }, []),
            },
            caption: publishedDetail?.nutritions_facts_note,
            instructionsTitle: labels?.cookingInstructionsTitle,
            instructions: publishedDetail?.methods
              ?.map(({ cooking_methods_id }: { cooking_methods_id: any }) => {
                const translatedMethod = getTranslations(
                  cooking_methods_id,
                  "translations",
                  intl.locale
                );
                return {
                  status: translatedMethod?.status,
                  title: translatedMethod?.title,
                  description: translatedMethod?.description,
                  logos: translatedMethod?.icons_list
                    ?.map(
                      ({
                        cooking_methods_icons_id,
                      }: {
                        cooking_methods_icons_id: any;
                      }) => {
                        const translatedIcon = getTranslations(
                          cooking_methods_icons_id,
                          "translations",
                          intl.locale
                        );
                        return {
                          status: translatedIcon?.status,
                          src: getAssetURL(translatedIcon?.image),
                          alt: translatedIcon?.image_alt,
                        };
                      }
                    )
                    ?.filter(({ status }: { status: string }) =>
                      isPublished(status)
                    ),
                };
              })
              ?.filter(({ status }: { status: string }) => isPublished(status)),
          };
        })
        ?.filter(({ status }: { status: string }) => isPublished(status)),
    };
  }, [getRootTranslatedData, intl]);

  const getQuestionsData = useCallback((): QuestionsAndAnswersProps | null => {
    const translatedData = getRootTranslatedData();
    if (!isPublished(translatedData?.faq_status)) return null;

    return {
      title: translatedData?.faq_title,
      subtitle: translatedData?.faq_subtitle,
      button: {
        label: labels?.viewAllFaqs,
        link: ROUTES.FAQS,
      },
      cards: translatedData?.faq_cards?.map(
        (card: { title: string; description: string }) => ({
          title: card.title,
          description: card.description,
        })
      ),
    };
  }, [getRootTranslatedData, intl]);

  const getHowItsMadeData = useCallback((): HowItsMadeProps | null => {
    const translatedData = getRootTranslatedData();
    if (!isPublished(translatedData?.featured_recipe_status)) return null;

    return {
      title: translatedData?.how_its_made_title,
      description: translatedData?.how_its_made_description,
      image1: {
        src: getAssetURL(translatedData?.how_its_made_medium_image),
        alt: translatedData?.how_its_made_medium_image_alt,
      },
      image2: {
        src: getAssetURL(translatedData?.how_its_made_small_image),
        alt: translatedData?.how_its_made_small_image_alt,
      },
      image3: {
        src: getAssetURL(translatedData?.how_its_made_large_image),
        alt: translatedData?.how_its_made_large_image_alt,
      },
      ctaLabel: translatedData?.how_its_made_button_label,
      ctaLink: translatedData?.how_its_made_button_link,
    };
  }, [getRootTranslatedData, intl]);

  const getWantMoreData = useCallback((): WantMoreProps | null => {
    const translatedData = getRootTranslatedData();
    if (!isPublished(translatedData?.want_more_beyond_status)) return null;

    return {
      title: translatedData?.want_more_beyond_title,
      cardTitle: translatedData?.want_more_beyond_subtitle,
      description: translatedData?.want_more_beyond_description,
      button: {
        label: labels?.viewNow,
        link: translatedData?.want_more_beyond_button_link,
      },
      image: {
        src: getAssetURL(translatedData?.want_more_beyond_image),
        alt: translatedData?.want_more_beyond_image_alt,
      },
    };
  }, [getRootTranslatedData, intl]);

  const getFeaturedRecipeData = useCallback((): FeaturedRecipeProps | null => {
    const translatedData = getRootTranslatedData();

    const { recipe = [] } =
      getTranslations(
        translatedData,
        "featured_recipe_link.translations",
        intl.locale
      ) ?? {};

    const translatedRecipe =
      getTranslations(recipe, "translations", intl.locale) ?? {};
    return {
      isPublished: isPublished(translatedData?.featured_recipe_status),
      picture: {
        src: getAssetURL(translatedRecipe?.image),
        alt: translatedRecipe?.image_alt,
      },
      pictureTitle: labels?.featuredRecipes,
      pictureSubtitle: translatedRecipe?.title,
      recipeLink: `${ROUTES.RECIPES}/${translatedData?.featured_recipe_link?.recipe_page_slug}`,
      title: translatedData?.featured_recipe_title,
      description: translatedData?.featured_recipe_description,
      button: {
        label: labels?.moreRecipes,
        link: ROUTES.RECIPES,
      },
    };
  }, [getRootTranslatedData, intl]);

  const getSeoData = useCallback(() => {
    const translatedData = getRootTranslatedData();
    if(variantId){
      const translatedVariant = getTranslations(
        translatedData?.product?.translations[0]?.variants.find((variant: any) => variant?.product_variant_id?.id === variantId),
        "product_variant_id.translations",
        intl.locale
      );
      if(translatedVariant?.seo_information)
        return parseSEO(translatedVariant, intl?.locale)
    }
  
    return parseSEO(translatedData, intl?.locale)
  },[getRootTranslatedData, intl]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={
        <SEO
          {...getSeoData()}
        />
      }
    >
      <ProductDetailInfo
        {...getProductDetailInfoData()}
        selectedVariantId={variantId}
      />
      <FeaturedRecipe {...getFeaturedRecipeData()} />
      <HowItsMade {...getHowItsMadeData()} desktopTopStyle={0} />
      <QuestionsAndAnswers {...getQuestionsData()} />
      <WantMore {...getWantMoreData()} />
    </Page>
  );
};

export default Product;

export const query = graphql`
  query ($id: ID!, $locale: String) {
    directus {
      product_page_by_id(id: $id) {
        product_page_slug
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          id
          want_more_beyond_button_link
          languages_code {
            code
            name
          }
          status
          variants_status
          product {
            id
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              status
              product_name
              variants {
                product_variant_id {
                  id
                  variant_slug
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    seo_information {
                      ...SEO
                    }
                    languages_code {
                      code
                      name
                    }
                    status
                    title
                    subtitle
                    subtitle_enabled
                    description
                    small_image {
                      id
                      filename_disk
                    }
                    small_image_alt
                    large_image {
                      id
                      filename_disk
                    }
                    large_image_alt
                    options {
                      available_quantity_id {
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          status
                          quantity
                          image {
                            id
                            filename_disk
                          }
                          image_alt
                          shop_now_link
                          find_near_me_link
                        }
                      }
                    }
                    detail {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        status
                        badges {
                          badges_id {
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              languages_code {
                                code
                                name
                              }
                              status
                              image {
                                id
                                filename_disk
                              }
                              image_alt
                            }
                          }
                        }
                        badges_disclaimer
                        ingredients_detail
                        nutritions_facts_details
                        nutritions_facts_note
                        nutritions_facts_data
                        accordion_headline
                        accordion_subheadline
                        accordion_items
                        methods {
                          cooking_methods_id {
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              languages_code {
                                code
                                name
                              }
                              status
                              title
                              description
                              icons_list {
                                cooking_methods_icons_id {
                                  translations(
                                    filter: {
                                      languages_code: { code: { _eq: $locale } }
                                    }
                                  ) {
                                    languages_code {
                                      code
                                      name
                                    }
                                    status
                                    image {
                                      id
                                      filename_disk
                                    }
                                    image_alt
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          featured_recipe_description
          featured_recipe_status
          featured_recipe_title
          featured_recipe_link {
            recipe_page_slug
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              status
              recipe_content_status
              recipe {
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  title
                  image {
                    id
                    filename_disk
                  }
                  image_alt
                }
              }
            }
          }
          how_its_made_status
          how_its_made_title
          how_its_made_description
          how_its_made_button_label
          how_its_made_button_link
          how_its_made_small_image {
            id
            filename_disk
          }
          how_its_made_small_image_alt
          how_its_made_medium_image {
            id
            filename_disk
          }
          how_its_made_medium_image_alt
          how_its_made_large_image {
            id
            filename_disk
          }
          how_its_made_large_image_alt
          faq_status
          faq_title
          faq_subtitle
          faq_cards
          want_more_beyond_status
          want_more_beyond_title
          want_more_beyond_subtitle
          want_more_beyond_description
          want_more_beyond_button_link
          want_more_beyond_image {
            id
            filename_disk
          }
          want_more_beyond_image_alt
        }
      }
    }
  }
`;
