export const cleanHTMLString = (html: string): string => {
  try {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = html;
    traverseDom(wrapper.firstChild, function (element) {
      if (element.removeAttribute) {
        element.removeAttribute("id");
        element.removeAttribute("style");
        element.removeAttribute("class");
      }
    });

    const result = wrapper.innerHTML;
    wrapper.style.display = "none";
    return result;
  } catch (e) {
    return html;
  }
};

const traverseDom = (node, func) => {
  func(node);
  node = node.firstChild;
  while (node) {
    traverseDom(node, func);
    node = node.nextSibling;
  }
};
