import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Image } from "../../types";

import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwMobile,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type HowItsMadeProps = {
  title: string;
  description?: string;
  ctaLabel: string;
  ctaLink?: string;
  image1: Image;
  image2: Image;
  image3: Image;
  desktopTopStyle?: number;
};

const HowItsMade: FC<HowItsMadeProps> = ({
  desktopTopStyle = 0,
  title,
  description,
  ctaLabel,
  ctaLink,
  image1,
  image2,
  image3,
}: HowItsMadeProps) => {
  if (!title && !description && !image1 && !image2 && !image3) {
    return null;
  }

  return (
    <Container>
      <LeftContent>
        {title && <Title>{title}</Title>}
        {description && (
          <Description aria-hidden={!description}>{description}</Description>
        )}
        {ctaLink && (
          <CTAButton
            label={ctaLabel}
            link={ctaLink}
            type={ButtonType.PRIMARY_LIGHT}
          />
        )}
      </LeftContent>
      <RightContent desktopTopStyle={desktopTopStyle}>
        <LeftImagesContainer>
          {image1?.src && <Image1 src={image1?.src} alt={image1?.alt} />}
          {image2?.src && <Image2 src={image2?.src} alt={image2?.alt} />}
        </LeftImagesContainer>
        {image3?.src && <Image3 src={image3?.src} alt={image3?.alt} />}
      </RightContent>
    </Container>
  );
};

export default HowItsMade;

const Container = styled.section`
  background-color: #304f4c;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
  }
`;

const LeftContent = styled.div`
  padding: ${toVWMobile(5)}vw ${toVWMobile(24)}vw ${toVWMobile(70)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: ${toVWDesktop(167)}vw ${toVWDesktop(125)}vw ${toVWDesktop(167)}vw
      ${toVWDesktop(205)}vw;
  }
`;

const RightContent = styled.div<{ desktopTopStyle: number }>`
  display: flex;
  align-items: flex-end;
  position: relative;
  top: ${toVWMobile(-50)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${(props) => props.desktopTopStyle}vw;
  }
`;

const Title = styled.h4`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  letter-spacing: 0.03em;
  margin-bottom: ${toVWMobile(12)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  line-height: ${toREM(40)}rem;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(60)}rem;
    line-height: ${toREM(60)}rem;
    margin-bottom: ${toVWDesktop(15)}vw;
  }
`;

const Description = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: ${toVWMobile(28)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(24)}vw;
  }
`;

const CTAButton = styled(ButtonLink)`
  margin-top: ${vwMobile(40)};
  align-self: flex-start;
  ${MEDIA_DESKTOP} {
    margin-top: ${vwDesktop(40)};
  }
`;

const LeftImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 ${toVWMobile(19)}vw 0 ${toVWMobile(15)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    position: relative;
    bottom: ${toVWDesktop(-30)}vw;
    margin: ${(props) =>
      props.theme.isRTL
        ? `0 0 0 ${toVWDesktop(20)}vw`
        : `0 ${toVWDesktop(20)}vw 0 0`};
  }
`;

const Image1 = styled.img`
  object-fit: cover;
  width: ${toVWMobile(181)}vw;
  height: ${toVWMobile(226)}vw;
  margin-bottom: ${toVWMobile(22.5)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(294)}vw;
    height: ${toVWDesktop(367)}vw;
    margin-bottom: ${toVWDesktop(32)}vw;
  }
`;

const Image2 = styled.img`
  object-fit: cover;
  width: ${toVWMobile(147)}vw;
  height: ${toVWMobile(185)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(189)}vw;
    height: ${toVWDesktop(237)}vw;
  }
`;

const Image3 = styled.img`
  object-fit: cover;
  width: ${toVWMobile(299)}vw;
  height: ${toVWMobile(362)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(395)}vw;
    height: ${toVWDesktop(535)}vw;
  }
`;
