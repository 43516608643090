import React, { FC, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { animated } from "react-spring";
import styled from "styled-components";
import { getAssetURL } from "../../helpers/assets";
import { rem, vwDesktop } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { ROUTES } from "../../helpers/routes";
import { DetailSection } from "./types";
import { getLink } from "../../helpers/link";

type CarouselProps = {
  active: number;
  slides: DetailSection[];
  productSlug: string;
};

const Carousel: FC<CarouselProps> = ({
  active = 0,
  slides = [],
  productSlug
}: CarouselProps) => {
  const scrollRef = useRef<ScrollContainer>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  const scrollToElement = useCallback((slideIndex=0)=>{
    const track = trackRef.current;
    const scroller = scrollRef.current?.getElement();

    if (!track || !scroller) return;

    const itemHeight =
      track.getBoundingClientRect().height / track.childElementCount;
    scroller.scrollTop = itemHeight * slideIndex;
  },[]);

  useLayoutEffect(() => {
    scrollToElement(slides.length > 3? slides.length : 0)
  }, []);

  const copiedSlides = [...slides];

  if (slides.length > 3) {
    copiedSlides.unshift(...slides);
    copiedSlides.push(...slides);
  }

  useEffect(() => {
    const element = scrollRef.current?.getElement();
    if (element) {
      element.setAttribute("aria-live", "polite");
      element.setAttribute("aria-roledescription", "carousel");
    }
  }, []);

  return (
    <Container
      ref={scrollRef}
      vertical={slides.length > 3}
      horizontal={false}
      aria-live={"polite"}
      aria-roledescription="carousel"
    >
      <Track ref={trackRef}>
        {copiedSlides.map((slide, index) => (
          <StyledItem
            aria-roledescription="slide"
            key={slide.product_variant_id}
            className={index % slides.length === active ? "active" : ""}
            href={getLink(`${ROUTES.PRODUCT_DETAIL}/${productSlug}/${slide.product_variant_slug}`)}
            aria-label={slide?.subtitle}
          >
            <div className="bg" />
            <img
              src={getAssetURL(slide?.image_1)}
              alt={slide?.image_1_alt || slide?.subtitle || slide?.title_1}
            />
            <div className="text">{slide?.subtitle}</div>
          </StyledItem>
        ))}
      </Track>
    </Container>
  );
};
export default Carousel;

const Container = styled(ScrollContainer)`
  display: none;
  cursor: grab;
  overflow-x: visible;

  ${MEDIA_DESKTOP} {
    display: flex;
    flex-direction: column;

    position: absolute;
    padding-top: ${vwDesktop(64)};
    top: 0;
    right: 0;
    height: ${vwDesktop(700)};
  }
`;

const Track = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    flex: 1 0 ${vwDesktop(170)};
  }
`;

const StyledItem = styled(animated.a)<{ bg?: string }>`
  position: relative;
  background-color: ${(p) => p.bg || "transparent"};
  cursor: pointer;
  transition: all 0.2s;
  transform: translateX(30%);
  text-align: center;
  text-decoration: none;

  &:hover {
    transform: translateX(0%);
    .text {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  &.active {
    transform: translateX(0%);
    .text {
      transform: translateX(0%);
      opacity: 1;
      color: #75c154;
    }

    .bg {
      height: 75%;
    }
  }

  .bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 120%;
    height: 0%;
    background-color: #1e4320;
    transition: all 0.3s;
    z-index: 0;
  }

  img {
    width: auto;
    height: ${vwDesktop(130)};
    z-index: 1;
    position: relative;
  }

  .text {
    color: white;
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    text-transform: uppercase;
    font-size: ${rem(16)};
    font-weight: 900;
    letter-spacing: 0.045em;
    transition: all 0.3s;
    z-index: 1;
    position: relative;
    transform: translateY(-50%);
    opacity: 0;
  }
`;
