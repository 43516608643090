import React, { FC } from "react";
import styled from "styled-components";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { MediaAsset } from "../../types";
import ButtonLink from "../shared/ButtonLink";

export type WantMoreProps = {
  title: string;
  cardTitle: string;
  description: string;
  button: { label: string; link: string };
  image: MediaAsset;
};

const WantMore: FC<WantMoreProps> = ({
  title,
  cardTitle,
  description,
  button,
  image,
}: WantMoreProps) => {
  if (!title && !cardTitle && !description && !image) {
    return null;
  }

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <TryWrapper>
        {image?.src && <Picture src={image?.src} alt={image?.alt} />}
        <TextContainer>
          {cardTitle && <TryTitle>{cardTitle}</TryTitle>}
          {description && <TrySubtitle>{description}</TrySubtitle>}
          {button?.label && (
            <ButtonLink link={button?.link} label={button?.label} />
          )}
        </TextContainer>
      </TryWrapper>
    </Container>
  );
};

const Container = styled.section`
  align-items: center;
  background-color: #012102;
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(40)}vw ${toVWMobile(24)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(80)}vw ${toVWDesktop(205)}vw ${toVWDesktop(120)}vw;
  }
`;

const Title = styled.h2`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.01em;
  line-height: ${toREM(40)}rem;
  margin-bottom: ${toVWMobile(78)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(66)}rem;
    line-height: ${toREM(60)}rem;
    letter-spacing: 0.03em;
    margin-bottom: ${toVWDesktop(72)}vw;
    padding: 0 ${toVWDesktop(24)}vw ${toVWDesktop(16)}vw;
    text-align: center;
    width: ${toVWDesktop(820)}vw;
  }
`;

const TryWrapper = styled.div`
  background-color: #7a3818;
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row-reverse;
    max-width: ${toVWDesktop(1030)}vw;
  }
`;

const TryTitle = styled(Title)`
  color: #ccb983;
  letter-spacing: 0.03em;
  margin-bottom: 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(60)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(60)}rem;
    padding: 0;
    margin-bottom: ${toVWDesktop(20)}vw;
    text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
    width: auto;
  }
`;

const TrySubtitle = styled(Title)`
  color: #ffffff;
  font-size: ${toREM(24)}rem;
  letter-spacing: ${toREM(0.72)}rem;
  line-height: normal;
  margin: ${toVWMobile(8)}vw 0 ${toVWMobile(30)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(40)}rem;
    letter-spacing: ${toREM(1.2)}rem;
    padding: 0;
    margin: 0 0 ${toVWDesktop(36)}vw;
    text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
    width: auto;
  }
`;

const Picture = styled.img`
  position: relative;
  top: -${toVWMobile(57)}vw;
  height: ${toVWMobile(237)}vw;
  width: 100%;
  object-fit: contain;
  align-self: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: -${toVWDesktop(40)}vw;
    right: -${toVWDesktop(40)}vw;
    height: ${toVWDesktop(362)}vw;
    width: ${toVWDesktop(520)}vw;
  }
`;

const TextContainer = styled.div`
  padding: ${toVWMobile(0)}vw ${toVWMobile(24)}vw ${toVWMobile(24)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(63)}vw ${toVWDesktop(80)}vw;
  }
`;

export default WantMore;
