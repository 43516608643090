import React, { useState } from "react";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";
import { getAssetURL } from "../../helpers/assets";
import { vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import Link from "../shared/Link";
import { ROUTES } from "../../helpers/routes";
import { getLink, openLink } from "../../helpers/link";
import { DetailSection } from "./types";

const CarouselMobile = ({
  slides,
  onSlideChange,
  productSlug,
  defaultIndex=0,
}: {
  slides: DetailSection[];
  onSlideChange: (i: number) => void;
  productSlug: string;
  defaultIndex: number
}): JSX.Element => {
  const [active, setActive] = useState(defaultIndex);

  const onChange = (i: number) => {
    setActive(i);
    onSlideChange(i);
    const slide = slides[i];
    if(slide){
      openLink(getLink(`${ROUTES.PRODUCT_DETAIL}/${productSlug}/${slide.product_variant_slug}`));
    }
  }

  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    initialSlide: defaultIndex,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    afterChange: onChange,
  };

  return (
    <Container>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Item
            to={`${ROUTES.PRODUCT_DETAIL}/${productSlug}/${slide.product_variant_slug}`}
            key={slide.product_variant_id}
            active={active === index}
          >
            <img src={getAssetURL(slide?.image_1)} alt={slide?.image_1_alt} />
          </Item>
        ))}
      </Slider>
    </Container>
  );
};

export default CarouselMobile;

const Container = styled.div`
  padding: ${vwMobile(20)} 0;
  height: auto;
  overflow: hidden;
  ${MEDIA_DESKTOP} {
    display: none;
  }
`;

const Item = styled(Link)<{ active?: boolean }>`
  padding: 0 ${vwMobile(0)} 0;
  transition: all 0.2s;

  transform-origin: center bottom;

  ${(p) => !p.active && "transform: scale(0.7);"}

  img {
    width: ${vwMobile(250)};
    height: auto;
    height: auto;
  }
`;
